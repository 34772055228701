import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/Layout'

const SeasideFuture = () => {

  return (
    <Layout>
      <section>
        <h1>Seaside Future</h1>
        <h2>Proposed Projects, Charrettes, and Student Studios</h2>
        <h3>The Notre Dame Seaside Studio</h3>
        <h3>January 2012</h3>
        <StaticImage
          src='../../../src/images/notre_dame_2012.jpeg'
          alt='Notre Dame students with Robert Davis'
        />
        <p>
        In January 2012, two fourth-year studios from the University of Notre Dame’s School of Architecture, under the guidance of Professors Bill Ponko and Lucien Steil, 
        traveled to Seaside, Florida to participate in a week-long intensive project that allowed for personal exploration of the New Urban community, site documentation 
        and the opportunity to meet with Seaside Founder Robert Davis and several architects whose works fill the town.
        </p>
        <p>
        Prior to travelling to Seaside, the two studios formed groups of three or four students to research certain aspects of the community using documentation in the Portal 
        and then presented the results to their peers. The following day seventeen undergraduate students headed to Seaside to work on two projects: the first was to document 
        an existing structure for inclusion into the Portal, and the second was to complete a twenty-four hour esquisse on one of six currently undeveloped sites in the 
        Camp Smolian neighborhood. For the first project each student produced a hand-drawn elevation and a watercolor perspective. The work produced from the esquisse was 
        then critiqued by Robert Davis on the final day of the trip.
        </p>
        <p>
        While in Seaside, students were fortunate enough to stay in three residences: Group Therapy, Natchez House, and Now and Then. This afforded a chance to learn 
        personally the difference between suburban and New Urban living. The students were granted a private tour of Seaside by Robert Davis, and a tour of the undeveloped 
        sites by previous Town Architect Braulio Casas. Additionally, former Town Architect Scott Merrill accompanied by Robert Davis and Andrés Duany conducted a tour for 
        the purpose of showing the historical stages of development, Seaside typologies, and the Seaside code.
        </p>
        <p>
        The studio trip was scheduled to coincide with the presentation of the Seaside Prize to Scott Merrill, an event attended by a number of Seaside architects, and which 
        also served as a networking opportunity. The owners of the Krier House, Joe and Laurie Braga, graciously welcomed the student researchers into their home and explained 
        over the course of two hours their journey with Leon Krier to transform the house into his vision of built perfection.
        </p>
        <p>
        Upon returning to campus, the two studios spent four days drafting drawings and watercolors as part of a longer esquisse. Their work on both projects was submitted 
        for inclusion into the Seaside Research Portal. Students praised the visit to Seaside as an overwhelming success. It is the hope of these students recently returned 
        that all fourth-year students have the opportunity to participate in an intensive studio in Seaside.  
        </p>
      </section>
    </Layout>
  )
}

export default SeasideFuture
